import { setUrl } from '@/stores/url';
import { useEffect, type FC } from 'react';

interface UrlSetterProps {
  url: URL;
}

const UrlSetter: FC<UrlSetterProps> = ({ url }) => {
  useEffect(() => {
    setUrl(url);
  }, [url]);
  return <></>;
};

export default UrlSetter;
